import { IObj } from '../types/common.types'

type IFieldData = IObj<{
  nameInput: string
  placeholder: string
  type: string
  mask?: string
  instanceId?: string
  autoComplete?: string
  tabIndex?: number
  readonly?: string
}>

const TEST_NAME_REG_EXP = new RegExp('^[а-яёa-z -]+$', 'i')
const TEST_EMAIL_REG_EXP = /^(?!.*\.\.)(?!.*\.@)[-+\w.]+@([A-z0-9][-A-z0-9]+\.)+[a-z]{2,4}$/iu
const TEST_PHONE_REG_EXP = /^[+]?[0-9\s\-\(\)]{11,21}$/i

const NOT_FROM_RUSSIA = 1

const ENTER_CORRECT_FULLNAME = 'Введите корректное ФИО'
const ENTER_CORRECT_SPECIALITIES = 'Выберите специальность'
const ENTER_CORRECT_NAME = 'Введите корректное имя'
const ENTER_LAST_NAME = 'Введите корректную фамилию'
const ENTER_SECOND_NAME = 'Введите корректное отчество'
const ENTER_EMAIL_CORRECT = 'Введите корректный email'
const ENTER_EMAIL = 'Email не введен'
const ENTER_FULL_NAME = 'Введите ФИО'
const ENTER_JOB = 'Введите место работы'
const SAVING_MESSAGE = 'Одну секунду, сохраняем данные'
const ENTER_PASSWORD = 'Введите пароль'
const ENTER_CORRECT_PASSWORD = 'Введите корректный пароль'
const PASSWORD_IS_SHORT = 'Слишком короткий пароль'
const ENTER_PHONE = 'Введите номер'
const ENTER_CORRECT_PHONE = 'Введите корректный номер'
const ENTER_CITY = 'Выберите город из списка'
const ENTER_CORRECT_CITY = 'Введите полное название города'
const ENTER_PHONE_RUSSIA = 'После +7 должно идти 9'

enum FieldsNameVoc {
  firstName = 'firstName',
  secondName = 'secondName',
  lastName = 'lastName',
  fullName = 'fullName',
  email = 'email',
  emailNoAutoComplete = 'emailNoAutoComplete',
  password = 'password',
  passwordNoAutoComplete = 'passwordNoAutoComplete',
  confirmPassword = 'confirmPassword',
  city = 'city',
  cityValidate = 'cityValidate',
  phone = 'phone',
  workplace = 'workplace',
  Specialities = 'Specialities',
  country = 'country',
  countryCode = 'countryCode',
  RegionId = 'RegionId',
  politics = 'politics',
  textarea = 'textarea',
  calendarSelect = 'calendarSelect',
  // Для олимпиады - как она будет неактуальна, можно удалить
  team = 'team',
  education = 'education',
  faculty = 'faculty',
  course = 'course',
  capitan = 'capitan',
  radioSelection = 'radioSelection',
}

const loginInitValues = {
  [FieldsNameVoc.email]: '',
  [FieldsNameVoc.password]: '',
}

const CountriesVoc = {
  RU: { [FieldsNameVoc.countryCode]: 'RU', [FieldsNameVoc.country]: 'Россия' },
  KZ: { [FieldsNameVoc.countryCode]: 'KZ', [FieldsNameVoc.country]: 'Казахстан' },
}

const preferredCountries = ['ru', 'kz', 'am', 'az', 'by', 'mn', 'uz', 'md', 'kg', 'tj']

const fieldData: IFieldData = {
  [FieldsNameVoc.fullName]: {
    nameInput: 'Фамилия, имя, отчество',
    placeholder: ENTER_FULL_NAME,
    type: 'text',
    mask: '',
  },
  [FieldsNameVoc.lastName]: {
    nameInput: 'Фамилия',
    placeholder: 'Введите фамилию',
    type: 'text',
    mask: '',
  },
  [FieldsNameVoc.firstName]: {
    nameInput: 'Имя',
    placeholder: 'Введите имя',
    type: 'text',
    mask: '',
  },
  [FieldsNameVoc.secondName]: {
    nameInput: 'Отчество',
    placeholder: 'Введите отчество',
    type: 'text',
    mask: '',
  },
  [FieldsNameVoc.email]: {
    nameInput: 'Email',
    placeholder: 'Введите email',
    type: 'email',
    mask: '',
    autoComplete: 'email',
  },
  [FieldsNameVoc.emailNoAutoComplete]: {
    nameInput: 'Email',
    placeholder: 'Введите email',
    type: 'email',
    mask: '',
    autoComplete: 'new-email',
  },
  [FieldsNameVoc.password]: {
    nameInput: 'Пароль',
    placeholder: 'Введите пароль',
    type: 'text',
    mask: '',
    autoComplete: 'password',
  },
  [FieldsNameVoc.passwordNoAutoComplete]: {
    nameInput: 'Пароль',
    placeholder: 'Введите пароль',
    type: 'text',
    mask: '',
    autoComplete: 'new-password',
  },
  [FieldsNameVoc.confirmPassword]: {
    nameInput: 'Пароль',
    placeholder: 'Подтвердите новый пароль',
    type: 'text',
    mask: '',
  },
  [FieldsNameVoc.Specialities]: {
    nameInput: 'Ваша специальность',
    placeholder: 'Поиск по специальностям',
    type: '',
    instanceId: 'Specialities',
  },
  [FieldsNameVoc.country]: {
    nameInput: 'Страна',
    placeholder: 'Выберите страну',
    type: '',
    instanceId: 'country',
  },
  [FieldsNameVoc.RegionId]: {
    nameInput: 'Регион',
    placeholder: 'Выберите регион',
    type: '',
    instanceId: 'RegionId',
  },
  [FieldsNameVoc.city]: {
    nameInput: 'Город',
    placeholder: 'Введите город',
    type: 'text',
    mask: '',
  },
  [FieldsNameVoc.phone]: {
    nameInput: 'Телефон',
    placeholder: 'Введите номер',
    type: 'tel',
  },
  [FieldsNameVoc.workplace]: {
    nameInput: 'Место работы',
    placeholder: 'Введите организацию',
    type: 'text',
    mask: '',
  },
  // Поля для олимпиады
  [FieldsNameVoc.team]: {
    nameInput: 'Название команды',
    placeholder: 'Название команды',
    type: 'text',
    mask: '',
  },
  [FieldsNameVoc.education]: {
    nameInput: 'Учебное заведение',
    placeholder: 'Учебное заведение',
    type: 'text',
    mask: '',
  },
  [FieldsNameVoc.faculty]: {
    nameInput: 'Факультет/кафедра',
    placeholder: 'Факультет/кафедра',
    type: 'text',
    mask: '',
  },
  [FieldsNameVoc.course]: {
    nameInput: 'Курс',
    placeholder: 'Курс',
    type: 'text',
    mask: '',
  },
}
// Валидация для react-hook-form
const checkFLSName = (values: string, message: string, maxlength: number) => {
  if (values.length !== 0) {
    if (values.length > maxlength) {
      return message
    } else if (values.length < 2) {
      return message
    } else if (!TEST_NAME_REG_EXP.test(values as string)) {
      return message
    }
  }
}

const checkFullName = (values: string, message: string) => {
  if (values?.length !== 0) {
    const nameParts = values.trim().split(' ')
    for (const part of nameParts) {
      if (!TEST_NAME_REG_EXP.test(part)) {
        return message
      }
    }

    if (nameParts.length < 2) {
      return message
    }
  }
}

const specialitiesValidate = {
  required: '',
  validate: (values: any) => {
    if (values.length === 0) return ENTER_CORRECT_SPECIALITIES
  },
}

export const firstNameValidation = {
  required: '',
  validate: (values: string) => checkFLSName(values, ENTER_CORRECT_NAME, 20),
}
export const fullNameValidation = {
  required: ENTER_FULL_NAME,
  validate: (values: string) => checkFullName(values, ENTER_CORRECT_FULLNAME),
}
export const workplceValidation = {
  required: 'Введите место работы',
}

export const calendarSelectValidation = {
  required: 'Выберите дату',
}

export const lastNameValidation = {
  required: '',
  validate: (values: string) => checkFLSName(values, ENTER_LAST_NAME, 20),
}

export const secondNameValidation = {
  required: '',
  validate: (values: string) => checkFLSName(values, ENTER_SECOND_NAME, 20),
}

export const workplaceValidation = {
  required: '',
  validate: (values: string) => {
    if (values.length === 0) {
      return ENTER_JOB
    }
  },
}

export const emailValidation = {
  required: ENTER_EMAIL,
  validate: (values: string) => {
    if (!TEST_EMAIL_REG_EXP.test(values as string)) {
      return ENTER_EMAIL_CORRECT
    }
  },
}

export const emailValidationNotLK = {
  required: '',
  validate: (values: string) => {
    if (values?.length >= 1 && !TEST_EMAIL_REG_EXP.test(values as string)) {
      return ENTER_EMAIL_CORRECT
    }
  },
}

export const passwordValidation = {
  required: ENTER_PASSWORD,
  validate: (values: string) => {
    if (values.length <= 3) {
      return PASSWORD_IS_SHORT
    }
    if (values.length > 30) {
      return ENTER_CORRECT_PASSWORD
    }
  },
}

export const specialitiesValidation = {
  required: 'Выберите специальность',
}

export const phoneValidation = (country: string) => {
  return {
    required: ENTER_PHONE,
    validate: (values: string) => {
      if (values.length !== 0) {
        if (!TEST_PHONE_REG_EXP.test(values as string)) {
          return ENTER_CORRECT_PHONE
        }
      }

      if (values.length > 5 && country === 'ru') {
        const phoneArray = values.match(/\d/g) || []
        if (phoneArray[1] !== '9') return ENTER_PHONE_RUSSIA
      }
    },
  }
}
export const textAreaValidation = {
  required: 'Введите вопрос',
  validate: (values: string) => {
    const MIN_LETTERS = 10
    if (values.length < MIN_LETTERS) {
      return 'Cлишком короткий вопрос'
    }
  },
}

export const cityValidate = (cities: any, countryCode: string) => {
  return {
    required: countryCode === 'RU' ? 'Начните вводить' : '',
    validate: (values: string) => {
      if (countryCode !== 'RU') return null
      if (values.length < 2) return ENTER_CORRECT_CITY

      if (!cities.find((city: { value: string }) => city.value === values)) {
        return ENTER_CITY
      }
    },
  }
}

const specialFields = {
  noTrimed: [FieldsNameVoc.RegionId],
  noCapitalize: [
    FieldsNameVoc.password,
    FieldsNameVoc.confirmPassword,
    FieldsNameVoc.email,
    FieldsNameVoc.workplace,
    FieldsNameVoc.countryCode,
    FieldsNameVoc.fullName,
    'path',
  ],
  allCapitalized: [FieldsNameVoc.city],
  prepareIdsArr: [FieldsNameVoc.Specialities],
}

const DUPLICATE_ERROR = 'Пользователь с такой почтой уже зарегистрирован'
const UNKNOWN_ERROR = 'Непредвиденная ошибка. Попробуйте еще раз'

export {
  fieldData,
  loginInitValues,
  specialitiesValidate,
  CountriesVoc,
  preferredCountries,
  TEST_EMAIL_REG_EXP,
  TEST_NAME_REG_EXP,
  FieldsNameVoc,
  NOT_FROM_RUSSIA,
  specialFields,
  ENTER_CORRECT_NAME,
  ENTER_LAST_NAME,
  ENTER_SECOND_NAME,
  DUPLICATE_ERROR,
  UNKNOWN_ERROR,
  SAVING_MESSAGE,
}
